import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { SocialIcon } from 'react-social-icons'
import ContactForm from '../components/ContactForm/ContactForm'
import LayoutListView from '../components/LayoutListView'
import GoogleApiWrapper from '../components/GoogleMaps'

const contact = ({ location }) => {
  const coordinates = [-37.91066, 145.14698]
  return (
    <LayoutListView path={location.pathname} configID="1Q139rfGYb17ymbqTo0sQc">
      <div className="row" style={{ paddingBottom: '20px' }}>
        <div className="col-md-6 text-center">
          <h4>Find us on Social Media</h4>
          <SocialIcon
            url="https://www.facebook.com/MissionsInterlinkAus"
            style={{ marginLeft: '10px' }}
          />
          <SocialIcon
            url="https://www.youtube.com/channel/UCAy-sve7VvJB3lFBhG4f4Ig/featured"
            style={{ marginLeft: '10px' }}
          />
          <SocialIcon
            url="https://www.instagram.com/missionseekaus/"
            style={{ marginLeft: '10px' }}
          />
          <br />
        </div>
        <div className="col-md-6 text-center">
          <h4>
            <FontAwesomeIcon icon={faEnvelope} /> Mail
          </h4>
          <strong>Missions Interlink National Office</strong>
          <br />
          <p>
            <a href="mailto:admin@missionsinterlink.org.au">admin@missionsinterlink.org.au</a>
            <br />
            PO Box 5214, Pinewood VIC 3149
          </p>
        </div>
      </div>
      <div
        className="row full-width"
        style={{ backgroundColor: '#eee', padding: '40px', marginBottom: '30px' }}
      >
        <p className="col-md-12 text-center">Subscribe to the Missions Interlink Newsletter.</p>
        <a
          href="https://dc7eda14.sibforms.com/serve/MUIFADQ1zMeY8dwQ_My0E6cee7smABcZyXadhFrWV52zqk0yjeBj4wCmqc4Be2qetIlptN5_sB6eQSc_mwUHr-S_uy8laXH2XonnW2wi9Orvtsbzhsf2ZAI_X3bY7khzwXQArt4td_Yg279YOwYI4tG5B4IlR_TRcs17rdVy_SGGZAohP77MhKOJof5dhItVjkcsPp1w1IdCPNdu"
          className="btn btn-primary mx-auto"
        >
          Subscribe
        </a>
      </div>
      <ContactForm />
      <div className="map-container full-width text-center">
        <p>
          <strong>Missions Interlink National Office:</strong> 60-62 Duerdin St, Clayton, VIC 3168
        </p>
        <GoogleApiWrapper coordinates={coordinates} />
      </div>
    </LayoutListView>
  )
}

export default contact

contact.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
