import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import '../../styles/footer.css'
import SectionFooter from './SectionFooter'

const Footer = ({ editPageId }) => {
  return (
    <footer className="footer">
      <div className="container-full sectionContainer">
        <SectionFooter
          sectionTitle="Members Login"
          sectionBody={
            <>
              <p>Log in for our Members and Associates.</p>
              <Link
                className="btn btn-call-to-action btn-primary"
                to="/restricted/member-only-resources"
              >
                Member Login
              </Link>
            </>
          }
        />

        <SectionFooter
          sectionTitle="Contact Us"
          sectionBody={
            <>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> PO Box 5214 Pinewood VIC 3149
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} /> admin@missionsinterlink.org.au
              </p>
            </>
          }
        />

        <SectionFooter
          sectionTitle="Sign up for our Newsletter"
          sectionBody={
            <>
              <p>Missions Interlink Email Updates</p>
              <a
                className="btn btn-call-to-action btn-primary"
                href="https://dc7eda14.sibforms.com/serve/MUIFADQ1zMeY8dwQ_My0E6cee7smABcZyXadhFrWV52zqk0yjeBj4wCmqc4Be2qetIlptN5_sB6eQSc_mwUHr-S_uy8laXH2XonnW2wi9Orvtsbzhsf2ZAI_X3bY7khzwXQArt4td_Yg279YOwYI4tG5B4IlR_TRcs17rdVy_SGGZAohP77MhKOJof5dhItVjkcsPp1w1IdCPNdu"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign up!
              </a>
            </>
          }
        />
      </div>

      <div className="copyright">
        <div style={{ padding: '10px 0 20px 0' }}>
          <SocialIcon
            url="https://www.facebook.com/MissionsInterlinkAus"
            style={{ marginLeft: '20px' }}
          />
          <SocialIcon
            url="https://www.youtube.com/channel/UCAy-sve7VvJB3lFBhG4f4Ig/featured"
            style={{ marginLeft: '20px' }}
          />
          <SocialIcon
            url="https://www.instagram.com/missionseekaus/"
            style={{ marginLeft: '20px' }}
          />
        </div>
        <p>Copyright © {new Date().getFullYear()}, Missions Interlink</p>
        <p>
          Missions Interlink is a registered Business Name of Australian Evangelical Alliance Inc.
          ABN 54 056 007 820 A0012495P
        </p>

        <p>
          <Link to="/privacy">Privacy</Link>
        </p>

        {editPageId !== '' && (
          <div>
            <a
              href={`https://app.contentful.com/spaces/ra0r88kvhzmj/entries/${editPageId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              [Edit Page]
            </a>
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer

Footer.propTypes = {
  editPageId: PropTypes.node.isRequired
}
